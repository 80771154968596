import React from 'react'
import Layout from '../../components/layout'

import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../../components/seo'
import PageHeader from '../../components/header/product-page-header'
import Image from 'gatsby-image'

const YelaERP = () => {
  const data = useStaticQuery(graphql`
    query YelaERP {
      bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "products/yelaLogo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
      image: file(relativePath: { eq: "products/yelaImage.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const title = 'Yalla ERP (Yela)'

  return (
    <Layout bgColor>
      <SEO title="Yela ERP System" />
      <PageHeader
        productColor={'#24126a'}
        productLogo={data.logo.childImageSharp.fluid.src}
        className="pink"
        title={title}
        subtitle="Seamless processes, better business"
        background={data.bgImage.childImageSharp.fluid}
        previousPage="Products"
        previousPageLink="/products/"
        thisPage={title}
      />
      <div className="container cases-project-image pb-5">
        <div
          style={{
            position: 'relative',
            zIndex: 10,
            backgroundColor: '#fff'
          }}
        >
          <Image
            fluid={data.image.childImageSharp.fluid}
            style={{
              border: '15px solid #fff',
              marginTop: -270,
              width: '100%',
              position: 'relative'
            }}
            alt=""
          />
        </div>
      </div>
      <div className="col-md-6 pt-5 pb-5 mx-auto">
        <div className="row">
          <div className="col-md-3 text-left text-md-center">
            <h4 className="m-0">Overview</h4>
          </div>
          <div className="col-md-9">
            <p>
              Supply chain processes are best managed if handled and monitored
              through an ERP system. ERP systems can integrate several important
              processes within one complete system and help businesses run
              smoothly and without technical errors. This system enables
              streamlining and accelerating processes across the entire
              organisation, regardless of its scale.
            </p>
            {/*
            <ul style={{ listStyle: "none", marginLeft: 0, paddingLeft: 0 }}>
              <li style={{ paddingBottom: 5 }}>Client: Vodafone </li>
              <li style={{ paddingBottom: 5 }}>Industry: Retail Industry</li>
              <li style={{ paddingBottom: 5 }}>
                Service: IT Consultancy, Cloud Computing and Cyber Security
              </li>
              <li style={{ paddingBottom: 5 }}>
                Via:{" "}
                <Link className="link" to="">
                  digitalmarketinginstitute.com
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-3 text-left text-md-center">
            <h4 className="m-0">Challenge</h4>
          </div>
          <div className="col-md-9">
            <p>
              ERP systems in the IT industry largey provide holistic and large
              scale solutions at excessive costs. SMEs find it challenging to
              purchase an ERP based solution that caters to their needs and
              business scale.
            </p>
          </div>
        </div>
        <div className="row pt-5 pb-5">
          <div className="col-md-3  text-left text-md-center">
            <h4 className="m-0">Solution</h4>
          </div>
          <div className="col-md-9">
            <p>
              At Explorelogy, we provide ERP system solutions to businesses of
              any scale, especially SMEs, that are designed and customised to
              suit company specific requirements, scale of operation and budget;
              making your supply chain processes move faster and without
              interruptions.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default YelaERP
